import React, { useEffect, useState } from "react";
import { RadioGroup, Text, useNotifications } from "@ilc-technology/luik";
import UpgradeModal from "../Modal/UpgradeModal";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { FormattedMoney } from "../../Common/Helpers/MoneyHelper";
import { QuoteData } from "../../contexts/QuoteContext/QuoteContext";
import { HttpPatchOperationType, LineItem, PatchRequestBody, Quote } from "../../Common/Types";
import { LineItemSeeDetails } from "../LineItemSeeDetails/LinteItemSeeDetails";
import {
  ItemListNames,
  StandardTrackingEvent,
  trackAddToCartEvent,
  trackEvent,
  trackRemoveFromCartEvent,
} from "../../Common/services/Analytics";
import { useQuoteDetailsContext } from "../../contexts/QuoteDetailsContext";
import { logError } from "../../Common/services/ErrorService";
import { generateItemFromLineItem } from "../../Common/services/AnalyticsMappings";
import {
  getAccommodationOptions,
  getPriceDifferenceForChoosingAccommodation,
  getSelectedAccommodation,
  getTotalAfterChoosingAccommodation,
} from "../../Common/services/UpgradeService";
import RoundedCard from "../BaseComponents/RoundedCard";
import TBRadio from "../BaseComponents/TBRadio";
import TitleValueCell from "../BaseComponents/TitleValueCell";

type UpgradeAccommodationModalProps = {
  isModalOpen: boolean;
  isSaveInProgress: boolean;
  handleModalClose: () => void;
  quote: Quote;
  quoteDraft: QuoteData;
};

const UpgradeAccommodationModal = ({
  isModalOpen,
  isSaveInProgress,
  handleModalClose,
  quote,
  quoteDraft,
}: UpgradeAccommodationModalProps) => {
  const { labels } = useDatasources();
  const { updateQuote } = useQuoteDetailsContext();
  const [isLoading, setIsLoading] = useState(false);
  const notifications = useNotifications();
  const [selectedAccommodation, setSelectedAccommodation] = useState<LineItem | undefined>(undefined);

  const currentSegment = quoteDraft.quoteData.segments[0];
  const defaultAccommodation = getSelectedAccommodation(currentSegment);
  const accommodationOptions = getAccommodationOptions(currentSegment).sort((a, b) => {
    if (a.isOptional !== b.isOptional) {
      return Number(a.isOptional) - Number(b.isOptional);
    }
    return a.price.amount - b.price.amount;
  });

  useEffect(() => {
    setSelectedAccommodation(defaultAccommodation);
  }, [currentSegment]);

  useEffect(() => {
    if (accommodationOptions) {
      trackEvent(quote.opportunityUuid, StandardTrackingEvent.ViewItemList, {
        items: accommodationOptions.map((x) => {
          return generateItemFromLineItem(x, quoteDraft.quoteData, ItemListNames.AccommodationUpgrades);
        }),
      });
    }
  }, []);

  const upgradeAccommodation = async () => {
    if (selectedAccommodation && defaultAccommodation) {
      setIsLoading(true);

      const body: PatchRequestBody[] = [
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${selectedAccommodation.id}`,
          value: {
            isOptional: false,
          },
        },
        {
          op: HttpPatchOperationType.REPLACE,
          path: `/segments/0/lineItems/${defaultAccommodation.id}`,
          value: {
            isOptional: true,
          },
        },
      ];

      const result = await updateQuote(quoteDraft.quoteData, body);

      if (!result.isSuccessful) {
        logError(result.error);
        notifications.addErrorNotification({
          title: labels[LabelKey.error],
          description: labels[LabelKey.upgradeFailed],
        });
      } else {
        trackRemoveFromCartEvent(
          quote.opportunityUuid,
          generateItemFromLineItem(defaultAccommodation, quote, ItemListNames.AccommodationUpgrades)
        );
        trackAddToCartEvent(
          quote.opportunityUuid,
          generateItemFromLineItem(selectedAccommodation, quote, ItemListNames.AccommodationUpgrades)
        );
        notifications.addSuccessNotification({
          title: labels[LabelKey.success],
          description: labels[LabelKey.accommodationHasChanged],
        });
      }
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <UpgradeModal
      isOpen={isModalOpen}
      isSaving={isLoading}
      isSavingDisabled={defaultAccommodation?.sku == selectedAccommodation?.sku}
      closeHandler={handleModalClose}
      header={labels[LabelKey.changeAccommodation]}
      headerChildren={<Text variant="paragraph-body">{labels[LabelKey.changeAccommodationDescription]}</Text>}
      cancelHandler={handleModalClose}
      upgradeHandler={upgradeAccommodation}
    >
      <RoundedCard testId="upgrade-accommodation-modal">
        <RadioGroup
          aria-label="upgrade-accomodation-modal"
          data-testid="select-accommodation-radioButton"
          containerClassName="w-full"
          className="a-radio-group mb-6"
          isDisabled={isSaveInProgress}
          defaultValue={defaultAccommodation?.sku}
          value={selectedAccommodation?.sku}
        >
          {accommodationOptions?.map((lineItem) => {
            const totalPriceDiffForChoosing = getPriceDifferenceForChoosingAccommodation(currentSegment, lineItem.sku);
            const priceDiffForChoosing = getPriceDifferenceForChoosingAccommodation(
              currentSegment,
              lineItem.sku,
              false
            );
            const priceValue =
              defaultAccommodation?.sku != lineItem.sku
                ? FormattedMoney(totalPriceDiffForChoosing, 2, true)
                : labels[LabelKey.included];

            const weeklyPrice =
              defaultAccommodation?.sku != lineItem.sku ? FormattedMoney(priceDiffForChoosing, 2, true) : undefined;
            const weeklyPriceDisplay = labels[LabelKey.segmentPricePerWeek].replace(
              "{segmentPricePerWeek}",
              weeklyPrice ?? ""
            );

            return (
              <TBRadio
                key={lineItem.sku}
                title={lineItem.description}
                description={priceValue}
                value={lineItem.sku}
                isDisabled={isSaveInProgress}
                isSelected={selectedAccommodation?.sku === lineItem.sku}
                testIdPrefix={lineItem.sku}
                onClick={() => {
                  const chosen = currentSegment?.lineItems.find((l) => l.sku === lineItem.sku);
                  if (chosen) {
                    trackEvent(quote.opportunityUuid, StandardTrackingEvent.SelectItem, {
                      items: [
                        generateItemFromLineItem(chosen, quoteDraft.quoteData, ItemListNames.AccommodationUpgrades),
                      ],
                    });
                    setSelectedAccommodation(chosen);
                  }
                }}
              >
                <TitleValueCell
                  leftValueChildren={<LineItemSeeDetails lineItem={lineItem} color="text-gray-45" variant="label-sm" />}
                  rightTextValue={weeklyPrice ? weeklyPriceDisplay : ""}
                  rightTextVariant="label-sm"
                  options={{ rightTextClassName: "text-gray-45 whitespace-nowrap" }}
                />
              </TBRadio>
            );
          })}
        </RadioGroup>
        {selectedAccommodation && (
          <TitleValueCell
            leftTextValue={labels[LabelKey.totalAfter]}
            rightTextValue={
              currentSegment &&
              FormattedMoney(getTotalAfterChoosingAccommodation(currentSegment, selectedAccommodation.sku))
            }
            testId="upgrade-total-payment-amount"
          />
        )}
      </RoundedCard>
    </UpgradeModal>
  );
};

export default UpgradeAccommodationModal;
