import React from "react";
import { Text } from "@ilc-technology/luik";
import { LineItem, SalesItemType } from "../../../../Common/Types";
import { LineItemSeeDetails } from "../../../LineItemSeeDetails/LinteItemSeeDetails";
import { formatDateRange } from "../../../../Common/Helpers/DateHelper";
import { FormattedMoney } from "../../../../Common/Helpers/MoneyHelper";
import { useSessionContext } from "../../../../contexts/SessionContext/SessionContext";

interface QuoteItemProps {
  lineItem: LineItem;
}

const QuoteItem: React.FC<QuoteItemProps> = ({ lineItem }) => {
  const { language } = useSessionContext();

  //Switch statement so if in the future we want to change the details based on type we can easily do so
  const details = (() => {
    switch (lineItem.type) {
      case SalesItemType.Accommodation:
        if (lineItem.startAt != undefined && lineItem.endAt != undefined) {
          return `${formatDateRange(lineItem.startAt, lineItem.endAt, language)}`;
        }
        break;
      default:
        return lineItem.additionalDescription;
    }
  })();
  const price = `${FormattedMoney(lineItem.totalPrice)}`;
  return (
    <>
      <div className="flex flex-row justify-between gap-2">
        <div className="overflow-wrap-anywhere flex flex-col gap-2 md:gap-3">
          <Text variant="label-md-bold">{lineItem.description}</Text>
          {details && <Text variant="label-md">{details}</Text>}
          <LineItemSeeDetails lineItem={lineItem} />
        </div>
        <div className="flex-shrink-0">
          <Text variant="label-md">{price}</Text>
        </div>
      </div>
    </>
  );
};

export default QuoteItem;
