import React from "react";
import { Icon, IconButton, Text } from "@ilc-technology/luik";
import { PaymentDetails } from "../../../Common/Helpers/PaymentHelper";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../../Common/StoryblokTypes";
import Enrich from "../../../Common/services/TextEnricher";
import { useSessionContext } from "../../../contexts/SessionContext/SessionContext";
import { Money, PaymentStatus } from "../../../Common/Types";
import Surface from "../../Surface/Surface";
import { getBgColorForVariant } from "../../../Common/services/ColorService";

interface BannerCardProps {
  paymentDetails: PaymentDetails;
  totalAmount: number;
  paid: Money | null;
  referenceId: string | null;
  showStudentDetailsBtn: boolean;
}

const BannerCard: React.FC<BannerCardProps> = ({
  paymentDetails,
  totalAmount,
  paid,
  referenceId,
  showStudentDetailsBtn,
}) => {
  const { labels } = useDatasources();
  const { session } = useSessionContext();

  const remainingAmount = totalAmount - (paid?.amount ?? 0);

  const statusText =
    paymentDetails.status === PaymentStatus.PaymentSuccess || paymentDetails.status === PaymentStatus.PaymentProcessed
      ? paid
        ? remainingAmount !== 0
          ? Enrich(labels[LabelKey.paidReservationAmount], {
              money: { amount: paid.amount, currency: paid.currency },
            })
          : Enrich(labels[LabelKey.paidAmount], {
              money: { amount: paid.amount, currency: paid.currency },
            })
        : labels[LabelKey.paid]
      : paymentDetails.status === undefined
        ? paymentDetails.statusText
        : labels[LabelKey.reserved];

  const showPaymentReferenceNumber =
    (paymentDetails.status === PaymentStatus.PaymentSuccess ||
      paymentDetails.status === PaymentStatus.PaymentProcessed) &&
    referenceId;

  return (
    <div className="flex h-full w-full flex-col" data-testid="banner-card">
      <div className="inline-flex w-full md:mx-auto">
        <Surface className="a-gap-sm inline-flex w-full flex-col justify-between md:px-8 md:py-10">
          <div className="a-gap flex flex-col justify-between md:flex-row">
            <div className="inline-flex flex-col border-b border-neutral-300 pb-6 md:w-[568px] md:border-b-0 md:border-r md:pb-0 md:pr-8">
              <div className="flow-row mb-4 inline-flex items-center">
                <div
                  className={`inline-flex h-14 w-14 items-center justify-center rounded-full ${getBgColorForVariant(paymentDetails.color)}`}
                  data-testid="banner-card-header-icon"
                >
                  <Icon iconName={paymentDetails.icon} size="base" color="white" />
                </div>
                <Text as="h3" variant="heading-3-bold" className="ml-6" data-testid="banner-card-heading">
                  {Enrich(labels[LabelKey.paymentStatus_ThankYouMessage], { user: session.user })}
                </Text>
              </div>
              <Text as="p" variant="label-lg" data-testid="banner-card-subheading">
                {paymentDetails.headerMessage.split("\\n").map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                })}
              </Text>
            </div>
            <div className="inline-flex flex-col md:items-end md:self-center">
              <Text as="p" variant="label-lg-bold" data-testid="banner-card-status-title">
                {labels[LabelKey.status]}
              </Text>
              <Text as="p" variant="label-lg" className="md:text-end" data-testid="banner-card-status-text">
                {statusText}
              </Text>
              {showPaymentReferenceNumber && (
                <>
                  <Text as="p" variant="label-lg-bold" className="mt-4" data-testid="banner-card-reference-title">
                    {labels[LabelKey.paymentReferenceNumber]}
                  </Text>
                  <Text as="p" variant="label-lg" className="md:text-end" data-testid="banner-card-reference-text">
                    {referenceId}
                  </Text>
                </>
              )}
            </div>
          </div>
          {showStudentDetailsBtn && (
            <div className="mt-2 flex flex-row align-middle" data-testid="banner-card-show-student-details-button">
              <div className="mr-2">
                <IconButton
                  size="xs"
                  iconName="arrow-down"
                  intent="secondary-black"
                  aria-label=""
                  onPress={() => (location.hash = "#studentDetailsForm")}
                />
              </div>
              <div className="overflow-hidden">
                <a href="#studentDetailsForm" className="align-middle underline underline-offset-4">
                  {labels[LabelKey.updateStudentDetails]}
                </a>
              </div>
            </div>
          )}
        </Surface>
      </div>
    </div>
  );
};

export default BannerCard;
