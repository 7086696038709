import React, { useCallback, useEffect } from "react";
import { useDatasources } from "../../../contexts/StoryblokContext/StoryblokContext";
import { useLocation, useNavigate } from "react-router-dom";
import StudentDetailsFullUpgrade from "../../StudentDetails/StudentDetailsFullUpgrade";
import { TRUE } from "../../../Common/Constants";
import BannerCard from "./BannerCard";
import PaymentSummaryCard from "./PaymentSummaryCard";
import ConstructPaymentDetails from "../../../Common/Helpers/PaymentHelper";
import { Money, PaymentStatus, Quote } from "../../../Common/Types";
import WhatsNext from "./WhatsNext/WhatsNext";
import { getQuoteInformation } from "../../../Common/services/QuoteService";
import ContentLayout from "../../BaseComponents/ContentLayout";
import Banner from "./Banner";

interface PaymentSummaryProps {
  quote: Quote;
  reservationPrice?: Money;
}

const PaymentSummary: React.FC<PaymentSummaryProps> = ({ quote, reservationPrice }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { featureSettings } = useDatasources();
  const { quoteInfo } = getQuoteInformation(quote);

  const isStudentDetailsUpdateEnabled = featureSettings.isStudentDetailsUpdateEnabled === TRUE;

  const queryStrings = new URLSearchParams(search);
  const referenceId = queryStrings.get("referenceId");
  const paymentStatus = queryStrings.get("status") as PaymentStatus | null;
  const paymentAmount = queryStrings.get("amount") ? Number(queryStrings.get("amount")) : null;

  const timeoutInMinutes = paymentStatus === PaymentStatus.ReservationSuccess ? 60 * 24 : 60;

  const getPaymentDetails = useCallback((paymentStatus: PaymentStatus | null) => {
    return ConstructPaymentDetails(paymentStatus);
  }, []);

  const paymentDetails = getPaymentDetails(paymentStatus);
  const paymentCurrency = quoteInfo.prices.totalPrice.currency ?? "";

  const paid: Money | null =
    paymentAmount && paymentCurrency
      ? {
          amount: paymentAmount,
          currency: paymentCurrency,
        }
      : null;

  useEffect(() => {
    const handleTimeout = () => {
      navigate("/", { replace: true });
    };

    const timeoutId = setTimeout(handleTimeout, timeoutInMinutes * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <ContentLayout
      testId="paymentSummary-page"
      fullSizePanelShownFirst={true}
      banner={<Banner />}
      topStaticCard={
        <BannerCard
          paymentDetails={paymentDetails}
          paid={paid}
          totalAmount={quoteInfo.prices.totalPrice.amount ?? 0}
          referenceId={referenceId}
          showStudentDetailsBtn={isStudentDetailsUpdateEnabled}
        />
      }
      sidePanel={
        <>
          <PaymentSummaryCard
            quote={quoteInfo}
            reservationPrice={reservationPrice}
            paymentDetails={paymentDetails}
            totalAmount={quoteInfo.prices.totalPrice.amount}
            paid={paid}
          />
          {isStudentDetailsUpdateEnabled && <WhatsNext paymentStatus={paymentStatus} />}
        </>
      }
    >
      {isStudentDetailsUpdateEnabled && <StudentDetailsFullUpgrade />}
    </ContentLayout>
  );
};

export default PaymentSummary;
