import React, { useEffect, useState } from "react";
import { Text, useNotifications, Loading } from "@ilc-technology/luik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { LabelKey } from "../../Common/StoryblokTypes";
import { RelatedContact, User, useSessionContext } from "../../contexts/SessionContext/SessionContext";
import { convertToAccountUpdateRequest } from "../../Common/Helpers/AccountHelper";
import moment from "moment";
import { TRUE } from "../../Common/Constants";
import "./StudentDetailsFullUpgrade.scss";
import "intl-tel-input/build/css/intlTelInput.css"; // needed for IntlTelInput to be rendered properly
import Surface from "../Surface/Surface";
import { Formik } from "formik";
import * as Yup from "yup";
import { CustomTrackingEvent, trackEvent } from "../../Common/services/Analytics";
import ParentDetails from "./ParentDetails";
import StudentBasicDetails from "./StudentBasicDetails";
import Enrich from "../../Common/services/TextEnricher";
import MedicalDetails from "./MedicalDetails";
import { MedicalsUpsertRequest, upsertMedicals } from "../../apis/customerServiceApi";
import { ScrollToFieldError } from "../ErrorHandling/ScrollToFieldError";
import { BasicStudentDetails } from "../Pages/Payment/StudentDetails";
import { FormikHelpers } from "formik/dist/types";
import { Relationship } from "../../Common/Types";

interface StudentDetailsFullUpgradeProps {}

export interface PropsFromChilds {
  isPhoneNumberValid: boolean;
  studentDateOfBirth: string;
  isLoading: boolean;
  medicalUpdateBlocked: boolean;
}

type StudentDetailsFullUpgradeFrom = BasicStudentDetails & {
  isInvoiceEmailSelected: boolean;
  invoiceEmail: string;
  dateOfBirth: string;
  nationalityCountryCode: string;
  birthCountry: string;
  passportNumber: string;
  gender: string;
  // Latin names
  firstNameLatin: string;
  middleNameLatin: string;
  lastNameLatin: string;
  // Address details
  street: string;
  city: string;
  postalCode: string;
  // Parent details
  contactId: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  contactRole: string;
  isEmergencyContact: boolean;
  // Medical details
  allergies: string[];
  dietaryNeeds: string[];
  disabilities: string[];
  otherMedicalNeeds: string[];
  otherAllergies: string;
  otherDietaryNeed: string;
  medications: string;
  otherDisabilityNote: string;
  otherMedicalNote: string;
  // medical update blocking
  medicalUpdateBlocked: boolean;
};

const StudentDetailsFullUpgrade: React.FC<StudentDetailsFullUpgradeProps> = () => {
  type FormStep = "basic_details" | "parent_details" | "medical_details";

  const notifications = useNotifications();
  const { labels, featureSettings } = useDatasources();
  const [activeStep, setActiveStep] = useState(0);
  const { session, updateAccount, updateRelatedContact } = useSessionContext();
  const { user } = session;
  const [isLastStep, setIsLastStep] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [steps, setSteps] = useState<FormStep[]>(["basic_details"]);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isContactPhoneNumberValid, setIsContactPhoneNumberValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [medicalUpdateBlocked, setMedicalUpdateBlocked] = useState(false);

  useEffect(() => {
    setFormSteps(user.dateOfBirth, user.relatedContact);
  }, []);

  useEffect(() => {
    setIsLastStep(activeStep === steps.length - 1);
  }, [steps, activeStep]);

  const handleFormValueChanges = (data: Partial<PropsFromChilds>) => {
    if (data.isLoading !== undefined) {
      setIsLoading(data.isLoading);
    }

    if (data.studentDateOfBirth !== undefined) {
      setFormSteps(data.studentDateOfBirth, user.relatedContact);
    }

    if (data.medicalUpdateBlocked !== undefined) {
      setMedicalUpdateBlocked(data.medicalUpdateBlocked);
    }

    if (data.isPhoneNumberValid !== undefined) {
      const step = steps[activeStep];
      switch (step) {
        case "basic_details":
          setIsPhoneNumberValid(data.isPhoneNumberValid);
          break;
        case "parent_details":
          setIsContactPhoneNumberValid(data.isPhoneNumberValid);
          break;
        default:
          break;
      }
    }
  };

  const setFormSteps = (dateOfBirth: string, relatedContact: RelatedContact | undefined) => {
    setSteps(["basic_details"]);
    setMedicalUpdateBlocked(false);

    const userAge = moment(new Date()).diff(moment(dateOfBirth), "years");

    if (userAge < 18 && featureSettings.isParentDetailsEnabled === TRUE && !hasRelatedParentContact(relatedContact)) {
      setSteps((prev) => prev.concat("parent_details"));
    }
    if (featureSettings.isMedicalDetailsEnabled === TRUE) {
      setSteps((prev) => prev.concat("medical_details"));
    }
  };

  const hasRelatedParentContact = (relatedContact: RelatedContact | undefined): boolean => {
    const allowedRoles = [Relationship.Father, Relationship.Mother, Relationship.Guardian];
    return relatedContact !== undefined && relatedContact !== null && allowedRoles.includes(relatedContact.role);
  };

  const _renderStepContent = (step: number) => {
    const contentToRender = steps[step];
    switch (contentToRender) {
      case "basic_details":
        return <StudentBasicDetails onValueChange={handleFormValueChanges} />;
      case "parent_details":
        return <ParentDetails onValueChange={handleFormValueChanges} />;
      case "medical_details":
        return <MedicalDetails onValueChange={handleFormValueChanges} />;
      default:
        return <div>Not Found</div>;
    }
  };

  const initialValues: StudentDetailsFullUpgradeFrom = {
    firstName: user.firstName ?? "",
    middleName: user.middleName ?? "",
    lastName: user.lastName ?? "",
    email: user.email ?? "",
    isInvoiceEmailSelected: !!user.invoiceEmail,
    invoiceEmail: user.invoiceEmail ?? "",
    dateOfBirth: user.dateOfBirth ? moment(user.dateOfBirth).format("YYYY-MM-DD") : "",
    mobilePhone: user.mobilePhone ?? "",
    nationalityCountryCode: user.nationalityCountryCode ?? "",
    birthCountry: user.birthCountry ?? "",
    passportNumber: user.passportNumber ?? "",
    gender: user.gender ?? "",
    // Latin names
    firstNameLatin: user.firstNameLatin ?? null,
    middleNameLatin: user.middleNameLatin ?? "",
    lastNameLatin: user.lastNameLatin ?? null,
    // Address details
    street: user.street ?? "",
    city: user.city ?? "",
    postalCode: user.postalCode ?? "",
    country: user.country ?? "",
    // Parent details
    contactId: user.relatedContact?.id ?? null,
    contactFirstName: user.relatedContact?.firstName ?? "",
    contactLastName: user.relatedContact?.lastName ?? "",
    contactEmail: user.relatedContact?.email ?? "",
    contactPhone: user.relatedContact?.phone ?? "",
    contactRole: user.relatedContact?.role ?? "",
    isEmergencyContact: user.relatedContact?.isEmergencyContact ?? false,
    // Medical details
    allergies: [],
    dietaryNeeds: [],
    disabilities: [],
    otherMedicalNeeds: [],
    otherAllergies: "",
    otherDietaryNeed: "",
    medications: "",
    otherDisabilityNote: "",
    otherMedicalNote: "",
    medicalUpdateBlocked: false,
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((step) => step - 1);
    }
  };

  const handleSubmit = async (
    values: StudentDetailsFullUpgradeFrom,
    actions: FormikHelpers<StudentDetailsFullUpgradeFrom>
  ) => {
    const step = steps[activeStep];
    let apiCalls = [];
    switch (step) {
      case "basic_details":
        apiCalls.push(saveUserDetailsAsync(values));
        break;
      case "parent_details":
        apiCalls.push(saveParentDetailsAsync(values));
        break;
      case "medical_details":
        !medicalUpdateBlocked && apiCalls.push(saveMedicalDetailsAsync(values));
        break;
      default:
        break;
    }
    const result = await Promise.all(apiCalls);
    if (result.some((item) => !item.isSuccessful)) {
      notifications.addErrorNotification({
        title: labels[LabelKey.saveFailed],
        description: labels[LabelKey.saveFailedDescription],
      });
      return;
    }
    if (activeStep == 0) {
      setFormSteps(values.dateOfBirth, user.relatedContact);
    }
    if (isLastStep) {
      notifications.addSuccessNotification({
        title: labels[LabelKey.success],
        description: labels[LabelKey.studentDetailsSaved],
      });
      setIsComplete(true);
    } else {
      setActiveStep((prev) => prev + 1);
      await actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const saveMedicalDetailsAsync = async (values: StudentDetailsFullUpgradeFrom) => {
    trackEvent(session.opportunity.id, CustomTrackingEvent.SaveMedicalDetailsCheckout);

    const upsertMedicalDetails = {
      flags: [...values.allergies, ...values.dietaryNeeds, ...values.disabilities, ...values.otherMedicalNeeds],
      otherMedicalNotes: values.otherMedicalNote,
      allergyNotes: values.otherAllergies,
      dietaryNotes: values.otherDietaryNeed,
      disabilityNotes: values.otherDisabilityNote,
      carriesMedication: values.medications,
    } as MedicalsUpsertRequest;

    return upsertMedicals(session.user.accountId, upsertMedicalDetails);
  };

  const saveUserDetailsAsync = async (values: StudentDetailsFullUpgradeFrom) => {
    trackEvent(session.opportunity.id, CustomTrackingEvent.SaveStudentDetailsCheckout);

    const account: User = {
      ...user,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      email: values.email,
      invoiceEmail: values.invoiceEmail,
      dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
      birthCountry: values.birthCountry,
      mobilePhone: values.mobilePhone,
      nationalityCountryCode: values.nationalityCountryCode,
      passportNumber: values.passportNumber,
      gender: values.gender,
      firstNameLatin: values.firstNameLatin,
      middleNameLatin: values.middleNameLatin,
      lastNameLatin: values.lastNameLatin,
      street: values.street,
      city: values.city,
      postalCode: values.postalCode,
      country: values.country,
      isFullUpdate: true,
    };

    const request = convertToAccountUpdateRequest(account);
    return updateAccount(request);
  };

  const saveParentDetailsAsync = async (values: StudentDetailsFullUpgradeFrom) => {
    trackEvent(session.opportunity.id, CustomTrackingEvent.SaveParentDetailsCheckout);

    const c: RelatedContact = {
      id: values.contactId === null ? undefined : values.contactId,
      firstName: values.contactFirstName,
      lastName: values.contactLastName,
      phone: values.contactPhone,
      email: values.contactEmail,
      role: Relationship[values.contactRole as keyof typeof Relationship],
      isEmergencyContact: values.isEmergencyContact,
    };

    return updateRelatedContact(c);
  };

  const isNumberValid = () => {
    switch (activeStep) {
      case 0:
        return isPhoneNumberValid;
      case 1:
        return isContactPhoneNumberValid;
      default:
        return true;
    }
  };
  const getValidationSchema = (activeStep: number): any => {
    if (steps[activeStep] == "parent_details") {
      return Yup.object({
        // Parent details
        contactId: Yup.string().notRequired(),
        contactFirstName: Yup.string().required(),
        contactLastName: Yup.string().required(),
        contactEmail: Yup.string().email().required(),
        contactPhone: Yup.string().required(),
        contactRole: Yup.string().required(),
        isEmergencyContact: Yup.string().notRequired(),
      });
    } else if (steps[activeStep] == "basic_details") {
      return Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        email: Yup.string().email().required(),
        isInvoiceEmailSelected: Yup.boolean(),
        invoiceEmail: Yup.string().when("isInvoiceEmailSelected", {
          is: true,
          then: (e) => e.email().required(),
          otherwise: (e) => e.email().notRequired(),
        }),
        dateOfBirth: Yup.date().required().max(moment().subtract(5, "years")),
        mobilePhone: Yup.string().required(),
        nationalityCountryCode: Yup.string().required(),
        passportNumber: Yup.string().notRequired(),
        gender: Yup.string().required(),
        birthCountry: Yup.string().notRequired(),
        // Latin names
        firstNameLatin:
          featureSettings.isLatinPersonalDetailsEnabled === TRUE ? Yup.string().required() : Yup.string().notRequired(),
        lastNameLatin:
          featureSettings.isLatinPersonalDetailsEnabled === TRUE ? Yup.string().required() : Yup.string().notRequired(),
        middleNameLatin:
          featureSettings.isLatinPersonalDetailsEnabled === TRUE ? Yup.string() : Yup.string().notRequired(),
        // Address details
        street: Yup.string().required(),
        city: Yup.string().required(),
        postalCode: Yup.string().required(),
        country: Yup.string().required(),
      });
    }
  };

  return (
    !isComplete && (
      <div data-testid="studentDetails-form">
        <Surface>
          <div className="mb-8">
            <Text variant="label-md" data-testid="steps-text">
              {Enrich(labels[LabelKey.studentUpdateSteps], {
                currentStep: activeStep + 1,
                stepsCount: steps.length,
              })}
            </Text>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(activeStep)}
            validateOnChange={false}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit, touched }) => (
              <form id="studentDetailsForm" onSubmit={handleSubmit}>
                {_renderStepContent(activeStep)}
                <ScrollToFieldError />
                <div
                  className={
                    (activeStep > 0 ? "lg:justify-between" : "lg:justify-end") +
                    " a-gap-sm mt-8 flex flex-col-reverse sm:flex-row"
                  }
                >
                  <>
                    {activeStep > 0 ? (
                      <input
                        data-testid="back-button"
                        className="cancel-details-button w-full sm:mx-6 lg:mx-0 lg:w-auto"
                        type="button"
                        disabled={isSubmitting || isLoading}
                        onClick={handleBack}
                        value={labels[LabelKey.back]}
                      />
                    ) : (
                      <></>
                    )}
                    {isSubmitting ? (
                      <div className="save-details-button save-details-loading w-full lg:mx-0 lg:w-auto">
                        <Loading className="loading save-details-loading-spinner" size="lg" />
                      </div>
                    ) : (
                      <input
                        data-testid="saveDetails-button"
                        className="save-details-button w-full sm:mx-6 lg:mx-0 lg:w-auto"
                        type="submit"
                        disabled={isLoading || ((touched.mobilePhone || touched.contactPhone) && !isNumberValid())}
                        value={isLastStep ? labels[LabelKey.save] : labels[LabelKey.saveAndContinue]}
                      />
                    )}
                  </>
                </div>
              </form>
            )}
          </Formik>
        </Surface>
      </div>
    )
  );
};

export default StudentDetailsFullUpgrade;
