import { DatePicker, Item, Select, TextInput, Text, Checkbox } from "@ilc-technology/luik";
import { LabelKey } from "../../Common/StoryblokTypes";
import IntlTelInput from "intl-tel-input/react";
import { useFormikContext } from "formik";
import { useDatasources } from "../../contexts/StoryblokContext/StoryblokContext";
import { useCallback, useState } from "react";
import { TRUE } from "../../Common/Constants";
import { PropsFromChilds } from "./StudentDetailsFullUpgrade";
import { nameofFactory } from "../../Common/Helpers/TextHelper";
import { getCountryName } from "../../Common/services/Countries";
import { useSessionContext } from "../../contexts/SessionContext/SessionContext";

interface StudentBasicDetailsProps {
  onValueChange: (data: Partial<PropsFromChilds>) => void;
}

interface StudentBasicDetailsFrom {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  isInvoiceEmailSelected: boolean;
  invoiceEmail: string;
  dateOfBirth: string;
  birthCountry: string;
  mobilePhone: string;
  nationalityCountryCode: string;
  passportNumber: string;
  gender: string;
  // Latin names
  firstNameLatin: string;
  middleNameLatin: string;
  lastNameLatin: string;
  // Address details
  street: string;
  city: string;
  postalCode: string;
  country: string;
}

const StudentBasicDetails: React.FC<StudentBasicDetailsProps> = ({ onValueChange }) => {
  const { language } = useSessionContext();
  const { labels, countries, genders, nationalities, featureSettings } = useDatasources();
  const [isPhoneNumberValid, setPhoneNumberValid] = useState(true);
  const nameofStudentBasicDetailsFrom = nameofFactory<StudentBasicDetailsFrom>();

  const formik = useFormikContext<StudentBasicDetailsFrom>();

  const onFormValueChange = (data: Partial<PropsFromChilds>) => {
    if (data.isPhoneNumberValid !== undefined) {
      setPhoneNumberValid(data.isPhoneNumberValid);
    }
    onValueChange(data);
  };

  const touchSelect = useCallback((elementName: string): ((isOpen: boolean) => Promise<void>) => {
    return async (isOpen) => {
      if (!isOpen) {
        await formik.setTouched({ ...formik.touched, [elementName]: true });
      }
    };
  }, []);

  return (
    <>
      <div className="a-gap flex flex-col">
        <div className="flex flex-col justify-between gap-2">
          <div className="flex-shrink-0">
            <Text variant="heading-5-bold">{labels[LabelKey.studentDetails]}</Text>
          </div>
          <div className="text-sm">
            <Text variant="paragraph-body">{labels[LabelKey.studentDetailsFullUpgradeDescription]}</Text>
          </div>
        </div>
        <div>
          <div className="a-gap-sm flex flex-col">
            <TextInput
              data-testid="firstName-input"
              isRequired={true}
              validationState={formik.errors.firstName && formik.touched.firstName ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              name={nameofStudentBasicDetailsFrom.firstName}
              label={labels[LabelKey.firstName]}
              placeholder={labels[LabelKey.firstName]}
              type="text"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstName}
            />
            {featureSettings.isMiddleNameEnabled === TRUE && (
              <TextInput
                data-testid="middleName-input"
                isRequired={false}
                errorMessage={labels[LabelKey.validationMessage]}
                name={nameofStudentBasicDetailsFrom.middleName}
                validationState={formik.errors.middleName && formik.touched.middleName ? "invalid" : "valid"}
                label={labels[LabelKey.middleName]}
                placeholder={labels[LabelKey.middleName]}
                type="text"
                value={formik.values.middleName}
                onChange={formik.handleChange}
                isDisabled={formik.isSubmitting}
                onBlur={formik.handleBlur}
                touched={formik.touched.middleName}
              />
            )}
            <TextInput
              data-testid="lastName-input"
              isRequired={true}
              name={nameofStudentBasicDetailsFrom.lastName}
              validationState={formik.errors.lastName && formik.touched.lastName ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              label={labels[LabelKey.lastName]}
              placeholder={labels[LabelKey.lastName]}
              type="text"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.lastName}
            />
            <TextInput
              data-testid="email-input"
              isRequired={true}
              name={nameofStudentBasicDetailsFrom.email}
              validationState={formik.errors.email && formik.touched.email ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationEmailMessage]}
              label={labels[LabelKey.email]}
              placeholder={labels[LabelKey.email]}
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
            />
            <Checkbox
              data-testid="invoice-email-checkbox"
              isSelected={formik.values.isInvoiceEmailSelected}
              onChange={async () =>
                await formik.setFieldValue(
                  nameofStudentBasicDetailsFrom.isInvoiceEmailSelected,
                  !formik.values.isInvoiceEmailSelected
                )
              }
            >
              <Text variant="paragraph-body">{labels[LabelKey.addInvoiceEmail]}</Text>
            </Checkbox>
            {formik.values.isInvoiceEmailSelected && (
              <TextInput
                data-testid="invoice-email-input"
                isRequired={true}
                name="invoiceEmail"
                validationState={formik.errors.invoiceEmail && formik.touched.invoiceEmail ? "invalid" : "valid"}
                errorMessage={labels[LabelKey.validationMessage]}
                label={labels[LabelKey.invoiceEmail]}
                placeholder={labels[LabelKey.invoiceEmail]}
                type="email"
                value={formik.values.invoiceEmail}
                onChange={formik.handleChange}
                isDisabled={formik.isSubmitting}
                onBlur={formik.handleBlur}
                touched={formik.touched.invoiceEmail}
              />
            )}
            <DatePicker
              data-testid="dateOfBirth-picker"
              label={labels[LabelKey.dateOfBirth]}
              errorMessage={labels[LabelKey.validationMessage]}
              validationState={formik.errors.dateOfBirth && formik.touched.dateOfBirth ? "invalid" : "valid"}
              name={nameofStudentBasicDetailsFrom.dateOfBirth}
              onChange={(e) => {
                formik.handleChange(e);
                onFormValueChange({ studentDateOfBirth: e.target.value });
              }}
              required={true}
              value={formik.values.dateOfBirth}
              disabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.dateOfBirth}
            />
            <div
              className={`tel-container a-rounded flex flex-col justify-center border border-neutral-300 
                    ${(formik.errors.mobilePhone && formik.touched.mobilePhone) || (formik.touched.mobilePhone && !isPhoneNumberValid) ? "tel-invalid" : ""} 
                    ${formik.isSubmitting ? "tel-container-disabled" : ""}`}
            >
              <IntlTelInput
                initialValue={formik.values.mobilePhone}
                onChangeValidity={(value: boolean) => {
                  onFormValueChange({ isPhoneNumberValid: value });
                }}
                onChangeNumber={async (value) =>
                  await formik.setFieldValue(nameofStudentBasicDetailsFrom.mobilePhone, value, true)
                }
                usePreciseValidation={true}
                initOptions={{
                  utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.1.1/build/js/utils.js",
                  containerClass: "tel",
                  nationalMode: false,
                  strictMode: false,
                  formatAsYouType: true,
                  customPlaceholder: function () {
                    return labels[LabelKey.phoneNumber];
                  },
                  initialCountry: "auto",
                  geoIpLookup: function (success, failure) {
                    fetch("https://ipapi.co/json")
                      .then(function (res) {
                        return res.json();
                      })
                      .then(function (data) {
                        success(data.country_code);
                      })
                      .catch(function () {
                        failure();
                      });
                  },
                }}
                inputProps={{
                  onBlur: formik.handleBlur(nameofStudentBasicDetailsFrom.mobilePhone),
                }}
              />
            </div>
            <Select
              name="nationality-select"
              label={labels[LabelKey.nationality]}
              className="mt-0"
              defaultSelectedKey={formik.values.nationalityCountryCode}
              validationState={
                formik.errors.nationalityCountryCode && formik.touched.nationalityCountryCode ? "invalid" : "valid"
              }
              errorMessage={labels[LabelKey.validationMessage]}
              trackingInfo="track-select"
              isRequired={true}
              onSelectionChange={async (value) =>
                await formik.setFieldValue(nameofStudentBasicDetailsFrom.nationalityCountryCode, value, true)
              }
              disabled={formik.isSubmitting}
              touched={formik.touched.nationalityCountryCode}
              onBlur={formik.handleBlur}
              onOpenChange={touchSelect(nameofStudentBasicDetailsFrom.nationalityCountryCode)}
            >
              {Object.entries(nationalities).map(([nationalityName, countryCode]) => (
                <Item key={countryCode} value={countryCode}>
                  {getCountryName(countryCode, language, nationalityName)}
                </Item>
              ))}
            </Select>
            <TextInput
              data-testid="passportNumber-input"
              isRequired={false}
              name={nameofStudentBasicDetailsFrom.passportNumber}
              errorMessage={labels[LabelKey.validationMessage]}
              validationState={formik.errors.passportNumber && formik.touched.passportNumber ? "invalid" : "valid"}
              label={labels[LabelKey.passportNumber]}
              placeholder={labels[LabelKey.passportNumber]}
              type="text"
              value={formik.values.passportNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isDisabled={formik.isSubmitting}
              touched={formik.touched.passportNumber}
            />
            {featureSettings.isBirthCountryEnabled === TRUE && (
              <Select
                name="birthCountry-select"
                label={labels[LabelKey.birthCountry]}
                className="mt-0"
                defaultSelectedKey={formik.values.birthCountry}
                isInvalid={formik.touched.birthCountry && Boolean(formik.errors.birthCountry)}
                errorMessage={labels[LabelKey.validationMessage]}
                trackingInfo="track-select"
                isRequired={false}
                onSelectionChange={async (value) =>
                  await formik.setFieldValue(nameofStudentBasicDetailsFrom.birthCountry, value, true)
                }
                disabled={formik.isSubmitting}
                touched={formik.touched.birthCountry}
                onBlur={formik.handleBlur}
                onOpenChange={touchSelect(nameofStudentBasicDetailsFrom.birthCountry)}
              >
                {Object.entries(countries).map(([countryCode, countryName]) => (
                  <Item key={countryCode} value={countryCode}>
                    {getCountryName(countryCode, language, countryName)}
                  </Item>
                ))}
              </Select>
            )}
            <Select
              name="gender-select"
              label={labels[LabelKey.gender]}
              defaultSelectedKey={formik.values.gender}
              validationState={formik.errors.gender && formik.touched.gender ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              className="my-0"
              trackingInfo="track-select"
              isRequired={true}
              onSelectionChange={async (value) =>
                await formik.setFieldValue(nameofStudentBasicDetailsFrom.gender, value, true)
              }
              disabled={formik.isSubmitting}
              touched={formik.touched.gender}
              onBlur={formik.handleBlur}
              onOpenChange={touchSelect(nameofStudentBasicDetailsFrom.gender)}
            >
              {Object.entries(genders).map(([name, value]) => (
                <Item key={name} value={value}>
                  {labels["gender_" + name] ?? name}
                </Item>
              ))}
            </Select>
          </div>
        </div>
      </div>

      {featureSettings.isLatinPersonalDetailsEnabled === TRUE && (
        <div className="a-gap-sm mt-8 flex flex-col" data-testid="latinPersonalDetails-form">
          <Text variant="label-lg-bold">{labels[LabelKey.studentLatinDetails]}</Text>
          <div className="a-gap-sm flex flex-col">
            <TextInput
              data-testid="firstNameLatin-input"
              isRequired={true}
              validationState={formik.errors.firstNameLatin && formik.touched.firstNameLatin ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              name={nameofStudentBasicDetailsFrom.firstNameLatin}
              label={labels[LabelKey.firstNameLatin]}
              placeholder={labels[LabelKey.firstNameLatin]}
              type="text"
              value={formik.values.firstNameLatin}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.firstNameLatin}
            />
            <TextInput
              data-testid="lastNameLatin-input"
              isRequired={true}
              name={nameofStudentBasicDetailsFrom.lastNameLatin}
              validationState={formik.errors.lastNameLatin && formik.touched.lastNameLatin ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              label={labels[LabelKey.lastNameLatin]}
              placeholder={labels[LabelKey.lastNameLatin]}
              type="text"
              value={formik.values.lastNameLatin}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.lastNameLatin}
            />
            <TextInput
              data-testid="middleNameLatin-input"
              isRequired={false}
              validationState={formik.errors.middleNameLatin && formik.touched.middleNameLatin ? "invalid" : "valid"}
              errorMessage={labels[LabelKey.validationMessage]}
              name={nameofStudentBasicDetailsFrom.middleNameLatin}
              label={labels[LabelKey.middleNameLatin]}
              placeholder={labels[LabelKey.middleNameLatin]}
              type="text"
              value={formik.values.middleNameLatin}
              onChange={formik.handleChange}
              isDisabled={formik.isSubmitting}
              onBlur={formik.handleBlur}
              touched={formik.touched.middleNameLatin}
            />
          </div>
        </div>
      )}

      <div className="a-gap-sm mt-8 flex flex-col">
        <Text variant="label-lg-bold">{labels[LabelKey.address]}</Text>
        <div className="a-gap-sm flex flex-col">
          <Select
            data-testid="country-select"
            name={nameofStudentBasicDetailsFrom.country}
            label={labels[LabelKey.country]}
            className="mt-0"
            defaultSelectedKey={formik.values.country}
            trackingInfo="track-select"
            isRequired={true}
            onSelectionChange={async (value) =>
              await formik.setFieldValue(nameofStudentBasicDetailsFrom.country, value, true)
            }
            disabled={formik.isSubmitting}
            touched={formik.touched.gender}
            onBlur={formik.handleBlur}
            onOpenChange={touchSelect(nameofStudentBasicDetailsFrom.country)}
          >
            {Object.entries(countries).map(([countryCode, countryName]) => (
              <Item key={countryCode} value={countryCode}>
                {getCountryName(countryCode, language, countryName)}
              </Item>
            ))}
          </Select>
          <TextInput
            data-testid="address-input"
            isRequired={true}
            name={nameofStudentBasicDetailsFrom.street}
            validationState={formik.errors.street && formik.touched.street ? "invalid" : "valid"}
            errorMessage={labels[LabelKey.validationMessage]}
            label={labels[LabelKey.address]}
            placeholder={labels[LabelKey.address]}
            type="text"
            value={formik.values.street}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.street}
          />
          <TextInput
            data-testid="postalCode-input"
            isRequired={true}
            name={nameofStudentBasicDetailsFrom.postalCode}
            validationState={formik.errors.postalCode && formik.touched.postalCode ? "invalid" : "valid"}
            errorMessage={labels[LabelKey.validationMessage]}
            label={labels[LabelKey.postalCode]}
            placeholder={labels[LabelKey.postalCode]}
            type="text"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.postalCode}
          />
          <TextInput
            data-testid="city-input"
            isRequired={true}
            name={nameofStudentBasicDetailsFrom.city}
            validationState={formik.errors.city && formik.touched.city ? "invalid" : "valid"}
            errorMessage={labels[LabelKey.validationMessage]}
            label={labels[LabelKey.city]}
            placeholder={labels[LabelKey.city]}
            type="text"
            value={formik.values.city}
            onChange={formik.handleChange}
            isDisabled={formik.isSubmitting}
            onBlur={formik.handleBlur}
            touched={formik.touched.city}
          />
        </div>
      </div>
    </>
  );
};

export default StudentBasicDetails;
